/*
 * Clever Canary
 * https://clevercanary.com/
 *
 * Clever Canary landing page.
 */

// Core dependencies
import {Link} from "gatsby";
import React from "react";

// App dependencies
import Layout from "../components/layout";

// Images
import github from "../../images/github.png";
import angular from "../../images/logo/cloud/logo-angular.png";
import aws from "../../images/logo/cloud/logo-aws.png";
import d3js from "../../images/logo/cloud/logo-d3js.png";
import docker from "../../images/logo/cloud/logo-docker.png";
import elastic from "../../images/logo/cloud/logo-elastic-search.png";
import excel from "../../images/logo/cloud/logo-excel.png";
import invision from "../../images/logo/cloud/logo-invision.png";
import java from "../../images/logo/cloud/logo-java.png";
import jscript from "../../images/logo/cloud/logo-js.png";
import jupyter from "../../images/logo/cloud/logo-jupyter.png";
import material from "../../images/logo/cloud/logo-material-design.png";
import node from "../../images/logo/cloud/logo-node.png";
import postgres from "../../images/logo/cloud/logo-postgres.png";
import python from "../../images/logo/cloud/logo-python.png";
import rabbit from "../../images/logo/cloud/logo-rabbit-mq.png";
import react from "../../images/logo/cloud/logo-react.png";
import reactiveX from "../../images/logo/cloud/logo-reactive-x.png";
import sketch from "../../images/logo/cloud/logo-sketch.png";
import socketIO from "../../images/logo/cloud/logo-socket-io.png";
import typescript from "../../images/logo/cloud/logo-typescript.png";
import webpack from "../../images/logo/cloud/logo-webpack.png";

// Styles
import compStyles from "./index.module.css";
import globalStyles from "../styles/global.module.css";

let classNames = require('classnames');

class IndexPage extends React.Component {

    render() {
        const description = "Clever Canary helps organizations fulfill the promise of genomics by making data, tools and results easier to discover, use and share.",
            title = "Tools and Resources for Open Genomics";
        return (
            <Layout description={description} title={title}>
                <div className={compStyles.home}>
                    <div className={compStyles.hero}>
                        <div className={classNames(globalStyles.wrapper, compStyles.heroWrapper)}>
                            <h1>Tools and Resources for Open Genomics</h1>
                            <h2>Clever Canary helps organizations fulfill the promise of genomics by making data, tools and results easier to discover, use and share.</h2>
                            <a className={compStyles.button}
                               href="mailto:hello@clevercanary.com"
                               title="Let's talk. Email us at hello at Clever Canary dot com">Let's Talk</a>
                        </div>
                    </div>
                    <div id="youAreWeAre" className={globalStyles.bgBlack}>
                        <div className={globalStyles.wrapper}>
                            <h2>
                                <span>You are an institutional leader.</span>
                                <span>We are product developers.</span>
                            </h2>
                            <h3>We'll work with your team to understand your users' goals and behaviors and help build an internet application that empowers discovery.</h3>
                        </div>
                    </div>
                    <div id="howCanWeHelp">
                        <div className={globalStyles.wrapper}>
                            <h2>Our Services</h2>
                            <ul className={compStyles.third}>
                                <li>
                                    <h4>Product Design</h4>
                                    <p>Requirements capture, current state analysis, success metrics definition, and option generation for improving the scientific workflow.</p>
                                </li>
                                <li>
                                    <h4>UI/UX Design</h4>
                                    <p>Design brief development and implementation. Review of current state, prior art, development and review of clickable mocks.</p>
                                </li>
                                <li>
                                    <h4>User Analytics</h4>
                                    <p>Google Analytics implementation with custom reports showing how the site is used in detail.</p>
                                </li>
                                <li>
                                    <h4>Community Portal Development</h4>
                                    <p>GatsbyJS "JAM Stack" portals with content in markdown, managed in Git.</p>
                                </li>
                                <li>
                                    <h4>Cohort Builder and Data Search Development</h4>
                                    <p>Schema driven faceted search and cohort download or export to cloud based execution environments.</p>
                                </li>
                                <li>
                                    <h4>Web App Development</h4>
                                    <p>CSS, Javascript, Typescript development in React or AngularJS.</p>
                                </li>
                                <li>
                                    <h4>Content Creation</h4>
                                    <p>Hero unit and value proposition definition, copywriting, technical writing, training materials and technical diagrams.</p>
                                </li>
                                <li>
                                    <h4>Search and Social Optimization</h4>
                                    <p>Search engine submission, automated search engine sitemaps, review and solicitation of inbound links.</p>
                                </li>
                                <li>
                                    <h4>Web Application Security Implementation</h4>
                                    <p>Work with your security team to address identified vulnerabilities and implement best practices.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div id="techCloud">
                        <div className={globalStyles.wrapper}>
                            <ul>
                                <li>
                                    <img src={react} alt="react"/>
                                    <img src={sketch} alt="sketch"/>
                                    <img src={aws} alt="aws"/>
                                    <img src={angular} alt="angular"/>
                                    <img src={postgres} alt="postgres"/>
                                    <img src={node} alt="node"/>
                                    <img src={docker} alt="docker"/>
                                    <img src={java} alt="java"/>
                                    <img src={invision} alt="invision"/>
                                    <img src={elastic} alt="elastic"/>
                                    <img src={jscript} alt="jscript"/>
                                </li>
                                <li>
                                    <img src={d3js} alt="d3js"/>
                                    <img src={excel} alt="excel"/>
                                    <img src={jupyter} alt="jupyter"/>
                                    <img src={material} alt="material"/>
                                    <img src={python} alt="python"/>
                                    <img src={rabbit} alt="rabbit"/>
                                    <img src={reactiveX} alt="reactiveX"/>
                                    <img src={socketIO} alt="socketIO"/>
                                    <img src={typescript} alt="typescript"/>
                                    <img src={webpack} alt="webpack"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div id="ourApproach" className={globalStyles.bgDarkGray}>
                        <div className={globalStyles.wrapper}>
                            <h2>Our Approach</h2>
                            <ul className={compStyles.third}>
                                <li>
                                    <h4>Understand the Current State</h4>
                                    <p>Use usage analytics and stakeholder interviews to understand user goals, usage patterns and pain points.</p>
                                </li>
                                <li>
                                    <h4>Brainstorm the Version Awesome</h4>
                                    <p>Work together to design a compelling solution that also collects actionable usage data.</p>
                                </li>
                                <li>
                                    <h4>Develop Iteratively and Incrementally</h4>
                                    <p>Approach the vision while learning from usage analytics and user interviews.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div id="ourWork">
                        <div className={globalStyles.wrapper}>
                            <h2><span>Our Work</span></h2>
                            <ul className={compStyles.third}>
                                <li>
                                    <h4><span>NHGRI </span><span>AnVIL</span></h4>
                                    <p>Project Portal</p>
                                    <p><a href="https://anvilproject.org"
                                          rel="noopener noreferrer"
                                          target="_blank">anvilproject.org</a>
                                    </p>
                                    <p>
                                        <a href="https://github.com/anvilproject/anvil-portal"
                                           rel="noopener noreferrer"
                                           target="_blank">
                                            <img src={github} alt="GitHub"/>
                                            <span>GitHub</span>
                                        </a>
                                    </p>
                                </li>
                                <li>
                                    <h4><span>CZI Human Cell </span><span>Atlas</span></h4>
                                    <p>Data Browser</p>
                                    <p>
                                        <a href="https://data.humancellatlas.org/explore"
                                           rel="noopener noreferrer"
                                           target="_blank">data.humancellatlas.org/explore</a>
                                    </p>
                                    <p>
                                        <a href="https://github.com/HumanCellAtlas/data-browser"
                                           rel="noopener noreferrer"
                                           target="_blank">
                                            <img src={github} alt="GitHub"/>
                                            <span>GitHub</span>
                                        </a>
                                    </p>
                                </li>
                                <li>
                                    <h4><span>CZI Human Cell </span><span>Atlas</span></h4>
                                    <p>Project Portal</p>
                                    <p>
                                        <a href="https://data.humancellatlas.org/"
                                          rel="noopener noreferrer"
                                          target="_blank">data.humancellatlas.org</a>
                                    </p>
                                    <p>
                                        <a href="https://github.com/HumanCellAtlas/data-portal"
                                           rel="noopener noreferrer"
                                           target="_blank">
                                            <img src={github} alt="GitHub"/>
                                            <span>GitHub</span>
                                        </a>
                                    </p>
                                </li>
                                <li>
                                    <h4><span>UCSC  </span><span>Xena</span></h4>
                                    <p>Genomics Explorer</p>
                                    <p>
                                        <a href="https://xenabrowser.net/"
                                           rel="noopener noreferrer"
                                           target="_blank">xenabrowser.net</a>
                                    </p>
                                    <p>
                                        <a href="https://github.com/ucscXena/ucsc-xena-client"
                                           rel="noopener noreferrer"
                                           target="_blank">
                                            <img src={github} alt="GitHub"/>
                                            <span>GitHub</span>
                                        </a>
                                    </p>
                                </li>
                                <li>
                                    <h4><span>UCSC  </span><span>Xena</span></h4>
                                    <p>Project Portal</p>
                                    <p>
                                        <a href="https://xena.ucsc.edu/"
                                           rel="noopener noreferrer"
                                           target="_blank">xena.ucsc.edu</a>
                                    </p>
                                    <p>
                                        <a href="https://github.com/ucscXena/xena-web"
                                           rel="noopener noreferrer"
                                           target="_blank">
                                            <img src={github} alt="GitHub"/>
                                            <span>GitHub</span>
                                        </a>
                                    </p>
                                </li>
                                <li>
                                    <h4><span>UCSC CGL </span><span>Boardwalk</span></h4>
                                    <p>Data Browser</p>
                                    <p>
                                        {/*<Link to="/projects/boardwalk">Design Note</Link>*/}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div id="pulications" className={globalStyles.bgDarkGray}>
                        <div className={globalStyles.wrapper}>
                            <h2>Papers</h2>
                            <p>
                                Goldman, M.J., Craft, B., Hastie, M. et al. Visualizing and interpreting cancer genomics data via the Xena platform. Nat Biotechnol (2020).
                                <a rel="noopener noreferrer"
                                                                                                                                                                               target="_blank"
                                                                                                                                                                               href="https://doi.org/10.1038/s41587-020-0546-8"> https://doi.org/10.1038/s41587-020-0546-8</a>


                            </p>
                            <br/>
                            <p>The open access preprint is available on BioRxiv.

                            <a rel="noopener noreferrer"
                               target="_blank"
                               href="https://www.biorxiv.org/content/10.1101/326470v6"> https://www.biorxiv.org/content/10.1101/326470v6</a>
                             </p>

                        </div>
                    </div>
                    <div id="caseStudies" className={globalStyles.bgGreen}>
                        <div className={globalStyles.wrapper}>
                            <h2>We're working to help to bring science online by developing tools and community resources for open,
                                reproducible, sharable genomic analysis. </h2>
                        </div>
                    </div>
                    <div id="letsWhiteboard">
                        <div className={globalStyles.wrapper}>
                            <h2>We'd love to discuss your project and how we can help move it forward.</h2>
                            <a href="mailto:hello@clevercanary.com"
                               title="Let's talk. Email us at hello at Clever Canary dot com" className={compStyles.button}>Let's
                                Whiteboard</a>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default IndexPage;
